export const errorPageStyles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    //justifyContent: "center",
    minHeight: "100vh",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  },
  content: {
    textAlign: "center" as const,
    maxWidth: "600px",
  },
  image: {
    marginBottom: "30px",
    width: "300px",
  },
  title: {
    fontSize: "28px",
    color: "#333",
    marginBottom: "15px",
  },
  description: {
    fontSize: "16px",
    color: "#666",
    marginBottom: "30px",
    lineHeight: "1.5",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },
  button: {
    backgroundColor: "#0078d4",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    textDecoration: "none",
  },
  secondaryButton: {
    backgroundColor: "transparent",
    color: "#0078d4",
    border: "1px solid #0078d4",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    textDecoration: "none",
    transition: "background-color 0.3s, color 0.3s",
  },
};
