import React, { CSSProperties, FC } from "react";

interface Props {
  children: any;
  type?: string;
  customStyle?: CSSProperties;
}

const ContentWrapper: FC<Props> = ({ children, type, customStyle }) => {
  if (type && type === "section") {
    return <section className="container wide-container">{children}</section>;
  } else {
    return (
      <div style={customStyle} className="main-wrapper">
        {children}
      </div>
    );
  }
};

export default ContentWrapper;
