/* eslint-disable @next/next/no-img-element */
"use client";

import Link from "next/link";
import { useEffect } from "react";
import ContentWrapper from "~/c/ContentWrapper";
import { errorPageStyles } from "./styles/errorPages";

interface ErrorProps {
  error: Error;
  reset: () => void;
}

export default function Error({ error }: ErrorProps) {
  useEffect(() => {
    // Optionally log the error to an error reporting service
    console.error(error);
  }, [error]);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <ContentWrapper type="section">
      <div style={errorPageStyles.container}>
        <div style={errorPageStyles.content}>
          <img
            src="/static/error/A2_Error_Gray.png"
            style={errorPageStyles.image}
            alt="AlternativeTo"
          />

          <h1 style={errorPageStyles.title}>Oops! Something went wrong</h1>
          <p style={errorPageStyles.description}>
            We&apos;re sorry, but it seems there was an error loading this page.
            Please try again or return to the homepage.
          </p>
          <div style={errorPageStyles.buttonContainer}>
            <button onClick={handleReload} style={errorPageStyles.button}>
              Reload Page
            </button>
            <Link href="/" style={errorPageStyles.secondaryButton}>
              Back to Homepage
            </Link>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}
